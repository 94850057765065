<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><b>MIS ACTIVIDADES</b></h4>
          </template>
          <div class="px-5 py-3">
            <div class="row" style="gap: 1rem">
              <div class="col">
                <!-- linar-style cursor glow -->
                <CardTable :data="dataCards" />
              </div>
            </div>
          </div>
          <div class="table-responsive px-4 mt-3">
            <table
              id="datatable_misActividades"
              class="table table-bordered table-responsive-md table-striped text-center"
            >
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>% Avance Evaluado</th>
                  <th>% Avance Jefe</th>
                  <th>Comentarios Jefe</th>
                  <th>Más Detalles</th>
                  <th>Estado Actividad</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in listaItems" :key="index">
                  <td>{{ item.nombre }}</td>
                  <td>{{ item.actividadProgreso.porcentajeAut }}%</td>
                  <td>{{ item.actividadProgreso.porcentajeResp }}%</td>
                  <td>{{ item.actividadProgreso.comentarioResp }}</td>
                  <td>
                    <div class="d-flex justify-content-center">
                      <b-button
                        class="d-flex"
                        v-b-modal.my-modal
                        @click="detalleActividad(item)"
                      >
                        <i class="fas fa-eye"></i
                      ></b-button>
                    </div>
                  </td>
                  <td>
                    <h4>
                      <b-badge
                        :variant="`${
                          item.actividadProgreso.porcentajeResp == 100
                            ? 'success'
                            : 'warning'
                        }`"
                      >
                        <i
                          :class="`fas ${
                            item.actividadProgreso.porcentajeResp == 100
                              ? 'fa-check'
                              : 'fa-clock'
                          }`"
                        ></i
                        >&nbsp;
                        {{
                          item.actividadProgreso.porcentajeResp == 100
                            ? "Terminada"
                            : "Pendiente"
                        }}
                      </b-badge>
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- modal ver mas datalles -->
          <b-modal
            title="Detalle Actividad"
            id="my-modal"
            body-bg-variant="dark"
            header-bg-variant="dark"
            footer-bg-variant="dark"
            size="lg"
            hide-footer
            @hidden="hideModal"
          >
            <small
              class="position-absolute text-success"
              style="right: 5px; top: 5px"
              >último progreso:
              {{ detailActividad.fechaCreacion | timeSince }}</small
            >
            <div>
              <h4 class="text-capitalize mb-2">
                información plan mejoramiento
              </h4>
              <p class="card-text font-italic">
                <b>Fecha inicio: </b
                >{{ detailActividad.fechaInicio | formatDate }}
              </p>
              <p class="card-text font-italic">
                <b>Fecha fin: </b>{{ detailActividad.fechaFin | formatDate }}
              </p>
              <p class="card-text font-italic">
                <b>Descripcion: </b>{{ detailActividad.descripcionPlan }}
              </p>
              <hr />
              <h4 class="text-capitalize mb-2">información de actividad</h4>
              <p class="card-text font-italic">
                <b>Usuario Responsable: </b
                >{{ detailActividad.usuarioResponsable }}
              </p>
              <p class="card-text font-italic">
                <b>Descripcion: </b>{{ detailActividad.descripcionActividad }}
              </p>
              <hr />
              <p class="card-text font-italic">
                <b class="text-primary">Mi comentario: </b
                >{{ detailActividad.actividadProgreso.comentarioAut }}
              </p>

              <!-- actions user -->
              <div class="d-flex flex-column" style="gap: 20px">
                <!-- registrar avance -->
                <b-button v-b-toggle="`collapse-1-inner`"
                  >Registrar avance <i class="fas fa-flag-checkered"></i
                ></b-button>
                <b-collapse :id="`collapse-1-inner`" class="mt-2">
                  <b-card>
                    <form
                      @submit.prevent="
                        sendActividadProgresoTrabajador(
                          detailActividad.actividadSelected
                        )
                      "
                    >
                      <div
                        class="d-flex flex-column justify-content-between"
                        style="gap: 20px"
                      >
                        <b-form-textarea
                          class="w-100"
                          v-model="actividadProgresoTrabajador.comentarioAut"
                          placeholder="Escriba un comentario..."
                          minlength="10"
                          maxlength="255"
                          required
                        ></b-form-textarea>
                        <div
                          class="d-flex justify-content-center align-items-center"
                        >
                          <input
                            class="mr-1 w-100"
                            type="range"
                            id="porcentaje"
                            v-model="
                              detailActividad.actividadProgreso.porcentajeAut
                            "
                            @input="detailActividad.changeInput = true"
                            min="0"
                            max="100"
                            step="0"
                          />
                          <span
                            style="font-size: 30px"
                            :class="`px-2 ${
                              detailActividad.actividadProgreso.porcentajeAut <
                              50
                                ? 'text-danger'
                                : detailActividad.actividadProgreso
                                    .porcentajeAut < 80
                                ? 'text-warning'
                                : 'text-primary'
                            }`"
                            ><small
                              >{{
                                detailActividad.actividadProgreso.porcentajeAut
                              }}%</small
                            ></span
                          >
                        </div>
                      </div>
                      <div class="text-right mt-3">
                        <b-button
                          block
                          type="submit"
                          variant="outline-light"
                          :disabled="!detailActividad.changeInput"
                        >
                          <i class="fas fa-flag-checkered"></i> Registrar
                        </b-button>
                      </div>
                    </form>
                  </b-card>
                </b-collapse>
              </div>
            </div>
          </b-modal>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import CardTable from "../../../components/cardTable/CardTable.vue";
import { core } from "../../../config/pluginInit";
import moment_timezone from "moment-timezone";
const Swal = require("sweetalert2");
export default {
  name: "MisActividades",
  data() {
    return {
      listaItems: [],
      listaPlanes: [],
      persona: {},
      actividadProgresoTrabajador: {
        planYMejoramientoId: null,
        actividadId: null,
        porcentajeAut: 0,
        comentarioAut: "",
        porcentajeResp: 0,
        comentarioResp: "",
        fechaCreacion: null,
        fechaCierre: null,
      },
      detailActividad: {
        fechaInicio: null,
        fechaFin: null,
        fechaCreacion: null,
        descripcionPlan: null,
        descripcionActividad: null,
        usuarioResponsable: null,
        actividadSelected: {},
        actividadProgreso: {},
        changeInput: false,
      },
      dataCards: [
        {
          title: "Actividades con avances",
          value: () =>
            this.listaItems.filter(
              (item) => item.actividadProgreso.porcentajeAut > 0
            ).length,
          iconName: "bolt",
          color: "rgba(71, 136, 255, 1)",
          tipo: "shield",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
        {
          title: "Nuevas actividades",
          value: () => {
            const fechaActual = new Date(); // Obtener la fecha actual
            fechaActual.setDate(fechaActual.getDate() - 3); // Restar tres días

            return this.listaItems.filter((item) => {
              const fechaCreacion = new Date(
                item.actividadProgreso.fechaCreacion
              );
              return fechaCreacion > fechaActual;
            }).length;
          },
          iconName: "calendar-plus",
          color: "rgba(223, 18, 46, 1)",
          tipo: "shield",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
      ],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    await this.getData();
    core.index();
    if (window.$.fn.DataTable.isDataTable("#datatable_misActividades")) {
      window.$("#datatable_misActividades").DataTable().destroy();
    }
    this.dataTableInstance = window.$("#datatable_misActividades").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
    this.$isLoading(false);
  },
  methods: {
    hideModal() {
      if (this.actividadProgresoTrabajador.comentarioAut !== null) {
        this.actividadProgresoTrabajador.comentarioAut = "";
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PlanYMejoramiento/ByPersonaId/" + this.persona.id,
        });
        console.log("list planMejoramiento...", res);
        if (res.length > 0) {
          this.listaPlanes = res;
          const data = await this.fetchAllItemsParallel(res);
          console.log("------------------->", data);
          this.listaItems = data;
        } else {
          this.listaPlanes = [];
        }
      } catch (error) {
        this.listaPlanes = [];
        console.log("err", error);
      }
    },
    detalleActividad(item) {
      const {
        actividadProgreso,
        fechaInicio,
        fechaFin,
        descripcionPlan,
        descripcion,
        usuarioResponsable,
      } = item;
      this.detailActividad = {
        actividadSelected: item,
        actividadProgreso: actividadProgreso,
        fechaInicio,
        fechaCreacion: actividadProgreso.fechaCreacion,
        fechaFin,
        descripcionPlan,
        descripcionActividad: descripcion,
        usuarioResponsable: `${usuarioResponsable.nombres} ${usuarioResponsable.apellidos}`,
      };
      console.log("----------->", this.detailActividad);
    },
    async sendActividadProgresoTrabajador(actividad) {
      try {
        let data = {
          ...this.actividadProgresoTrabajador,
          planYMejoramientoId: actividad.planId,
          actividadId: actividad.id,
          comentarioAut: this.actividadProgresoTrabajador.comentarioAut,
          porcentajeAut: actividad.actividadProgreso.porcentajeAut,
          fechaCreacion: this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DDTHH:mm:ss"),
          fechaCierre: this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DDTHH:mm:ss"),
        };
        let res = await this.$store.dispatch("hl_post", {
          path: "Actividad/CreateActividadProgresoTrabajador/",
          data,
        });
        console.log(res);
        if (res) {
          // Actualiza el progreso de la actividad en el cliente
          this.detailActividad.fechaCreacion = moment_timezone(
            new Date()
          ).format("YYYY-MM-DDTHH:mm:ss");
          this.detailActividad.actividadProgreso.comentarioAut =
            this.actividadProgresoTrabajador.comentarioAut;
          Swal.fire({
            title: "Listo!",
            text: "Avance registrado correctamente",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              console.log("confirmado");
              this.actividadProgresoTrabajador.comentarioAut = "";
              this.detailActividad.changeInput = false;
            }
          });
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getActividadById(actividadId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Actividad/GetActividad/" + actividadId,
        });
        console.log("actividadById...", res);
        return res;
      } catch (error) {
        console.log("err", error);
      }
    },
    async fetchAllItemsParallel(data) {
      const promises = [];
      for (let item of data) {
        let misActividades = item.actividadesId.split(",");
        for (let id of misActividades) {
          promises.push(
            this.getActividadById(id).then(async (actividad) => {
              let actividadProgresoResult =
                await this.getActividadByPlanIdAndActividadId(
                  item.id,
                  actividad.id
                );
              actividadProgresoResult.changeInput = false;
              return {
                ...actividad,
                fechaInicio: item.fechaInicio,
                fechaFin: item.fechaFin,
                descripcionPlan: item.descripcion,
                planId: item.id,
                actividadProgreso: actividadProgresoResult,
              };
            })
          );
        }
      }
      const results = await Promise.all(promises);
      return results;
    },
    async getActividadByPlanIdAndActividadId(planId, actividadId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path:
            "ActividadProgreso/GetActividadByPlanIdAndActividadId/" +
            planId +
            "/" +
            actividadId,
        });
        console.log("ActividadProgreso...", res);
        return res;
      } catch (error) {
        console.log("err", error);
      }
    },
  },
  filters: {
    formatDate(date) {
      const formattedDate = new Date(date).toLocaleDateString("es-ES");
      return formattedDate;
    },
    timeSince(dateString, timezone = "America/Bogota") {
      const dateInBogota = moment_timezone.tz(dateString, timezone);
      return dateInBogota.format("YYYY-MM-DD hh:mm A");
    },
  },
  components: { CardTable },
};
</script>

<style></style>
